const config = {
  spacing: {
    xs: 4,
    s: 8,
    sm: 10,
    m: 14,
    ml: 16,
    l: 24,
    xl: 32,
    xxl: 40,
    xxxl: 48,
  },
  breakpoints: {
    xs: 0,
    sm: 720,
    md: 960,
    lg: 1280,
    xl: 1920,
    up: (breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | number) => {
      if (typeof breakpoint === "number") {
        return `@media screen and (min-width: ${breakpoint}px)`;
      }
      return `@media screen and (min-width: ${config.breakpoints[breakpoint]}px)`;
    },
    down: (breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | number) => {
      if (typeof breakpoint === "number") {
        return `@media screen and (max-width: ${breakpoint}px)`;
      }
      return `@media screen and (max-width: ${config.breakpoints[breakpoint]}px)`;
    },
  },
  shape: {
    borderRadius: 2,
  },
  radius: {
    xs: 2,
    s: 4,
    sm: 8,
    m: 14,
    ml: 40,
    l: 320,
  },
  size: {
    xs: 325,
    sm: 840,
    md: 940,
    lg: 1024,
  },
  elevation: {
    xs: "0px 1px 0px #EFE8E5",
    s: "0px 2px 10px rgb(0 0 0 / 0.15)",
    sm: "0px 30px 20px -20px rgb(0 0 0 / 0.15)",
    m: "0px 30px 20px -20px rgb(0 0 0 / 0.05)",
  },
  typography: {
    family: {
      serif: "Sun Valley, serif",
      sansSerif: "Gibson, Helvetica, Arial, sans-serif",
    },
    size: {
      title: "56px",
      subtitle: "40px",
      heading: "32px",
      subheading: "24px",
      big: "18px",
      normal: "16px",
      small: "14px",
    },
    line: {
      title: "72px",
      subtitle: "60px",
      heading: "58px",
      subheading: "48px",
      big: "36px",
      paragraph: "28px",
      normal: "24px",
      small: "20px",
      initial: "initial",
    },
    weight: {
      light: 300,
      regular: 400,
      semibold: 500,
      bold: 700,
    },
  },
  colors: {
    primary: {
      main: "#00583D", // green.pea
    },
    secondary: {
      main: "#D0722A", // orange.brandy
    },
    error: {
      main: "#D20707",
    },
    background: {
      default: "#FDF9F7",
    },
    green: {
      pea: "#00583D",
      leaf: "#3E7C69",
      gulf: "#8AB2AA",
      patina: "#69A794",
      jet: "#B4D4CA",
      everglade: "#1E4836",
      swans: "#DDEFE9",
      eden: "#267059",
      light: "#E3F0EE",
      mint: "#60A592",
      brightSeafoam: "#08B07D",
      bright: "#F3F7F6",
      lightGulf: "#92C5B5",
      dullTeal: "#5B9B8F",
      brunswick: "#24583F",
    },
    orange: {
      tumbleweed: "#DFAB82",
      brandy: "#D0722A",
      medium: "#E27E31",
      bourbon: "#8C5327",
      pancho: "#ECBF9C",
      brandyLight: "#DA762A",
      sauvignon: "#F3E9E5",
    },
    beige: {
      almond: "#F0DACD",
      bianca: "#FDF9F7",
      linen: "#FCF1EB",
      lace: "#FDEFE6",
      light: "#F9EAE3",
      champagne: "#F9ECE2",
      bone: "#EAE6E3",
      silk: "#FADCC9",
    },
    pink: {
      peep: "#F6DCE7",
      azaela: "#F2B6CF",
      dawn: "#F9F4F2",
      peach: "#FCE9E2",
      cherub: "#FBDFEB",
      babyPink: "#FCEEF4",
      light: "#F7D5E3",
    },
    red: {
      monza: "#D20707",
      grenadier: "#CD2A01",
    },
    gray: {
      default: "#939393",
      silver: "#BEBEBE",
      smoke: "#EAEAEA",
      boulder: "#7D7D7D",
      albaster: "#F8F8F8",
      emperor: "#535353",
      soft: "#E5E5E5",
      light: "#DADADA",
    },
    base: {
      black: "#272727",
    },
    white: "#FFFFFF",
  },
};

export default config;
