import { type EventsPageState, TITLE_CHARACTERS_LIMIT } from "@hey-lady/shared/helpers/const";
import { type PlaceModel } from "@hey-lady/shared/types/generic";
import { type EnglishSkill, type Interest, UserModel } from "@hey-lady/shared/types/graphql";
import { type FragmentDefinitionNode, type OperationDefinitionNode } from "graphql";
import { isEmpty, isNil, split, take, trim } from "ramda";
import { type IntlShape } from "react-intl";
import { useLocation } from "react-router-dom";
import { UserFields } from "./enum";

export const noop = (): void => {};

export const truncateWithEllipses = (
  title: string,
  limit: number = TITLE_CHARACTERS_LIMIT,
): string => {
  const trimmedTitle = trim(title);
  if (trimmedTitle.length > limit) {
    return `${take(limit, trimmedTitle)}...`;
  }
  return trimmedTitle;
};

export const dangerouslySetInnerHTML = (content: string | null | undefined) => {
  if (content) {
    return {
      __html: content,
    };
  }
  return undefined;
};

export const readFileAsDataUrl = (
  file: File,
): Promise<string | null | ArrayBuffer | DOMException> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = () => {
      reject(new DOMException("Problem parsing file"));
    };
    fileReader.readAsDataURL(file);
  });
};

export const translateInterest = (interest: Interest, intl: IntlShape) => {
  return {
    id: interest,
    title: intl.formatMessage({ id: `ENUM_INTEREST_${interest}` }),
  };
};

export const translateEnglishSkill = (skill: EnglishSkill, intl: IntlShape) => {
  return {
    id: skill,
    title: intl.formatMessage({ id: `ENUM_ENGLISH_SKILL_${skill}` }),
  };
};

export const translateEnum = (intl: IntlShape, enumValue?: string | null) => {
  const formattedEnumValue = enumValue
    ? enumValue
        .trim()
        .toUpperCase()
        .replace(/ /g, "_")
        .replace(/-/g, "_")
        .replace(/,/g, "")
        .replace(/&/g, "AND")
        .replace(/\//g, "_")
    : "";

  return intl.formatMessage({ id: `ENUM_${formattedEnumValue}` });
};

export const convert12to24 = (initial: string | undefined) => {
  const [raw, meridian] = split(" ", initial ?? "");
  const [hour] = split(":", raw);

  if (hour === "12" && meridian === "PM") {
    return 12;
  }

  if (hour === "12" && meridian === "AM") {
    return 0;
  }

  return meridian === "PM" ? Number.parseInt(hour) + 12 : Number.parseInt(hour);
};

export const isSubscriptions = (definition: OperationDefinitionNode | FragmentDefinitionNode) => {
  return definition?.kind === "OperationDefinition" && definition?.operation === "subscription";
};

export const openWidget = () => {
  const groove = window.groove;
  groove.widget.open();
};

export const usePreviousStatePageNumber = () => {
  const state = useLocation().state as Partial<EventsPageState>;
  return state?.pageNumber;
};

export const getMentionsFromComment = (comment: string) => {
  let mentions: string[] = [];
  const mentionStart = 'data-id="';
  const mentionEnd = '"';
  const allMentions = comment.split(mentionStart);
  const [, ...relevantMentions] = allMentions;

  relevantMentions.forEach((mention: string) => {
    if (mention.includes(mentionEnd)) {
      const user = mention.split(mentionEnd)[0];
      mentions = [...mentions, user];
    }
  });

  return mentions;
};

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({
      top: element.offsetTop,
      behavior: "smooth",
    });
  }
};

export const cityAndCountry = (
  addresses: google.maps.GeocoderAddressComponent[] = [],
): PlaceModel => {
  return addresses.reduce((accumulator, address) => {
    if (address.types.includes("sublocality") || address.types.includes("locality")) {
      return { ...accumulator, city: address.long_name };
    }
    if (address.types.includes("country")) {
      return { ...accumulator, country: address.long_name, countryId: address.short_name };
    }
    return accumulator;
  }, {});
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const fillArray = (length: number, value: any) => {
  return new Array(length).fill(value);
};

/**
 * Debounce function was removed in Material UI v5.
 * The function below is provided the by Material UI documentation:
 * https://mui.com/x/react-date-pickers/lifecycle/#system-ServerInteraction.tsx
 */
export const debounce = (func: (...arg: any) => void, wait = 500) => {
  let timeout: NodeJS.Timeout;
  function debounced(...args: any) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
};

export const countWords = (content?: string): number => {
  const WORDS_REGEX = /\w+/g;

  if (content) {
    return content?.match(WORDS_REGEX)?.length ?? 0;
  }
  return 0;
};

// TODO find a greate place for this function
export const incompleteFieldsOnUserProfile = (user: UserModel): string[] => {
  const fields = [
    { name: UserFields.englishSkills, value: user.englishSkills },
    { name: UserFields.interests, value: user.interests },
    { name: UserFields.occupation, value: user.occupation },
    { name: UserFields.story, value: user.story },
    { name: UserFields.birthday, value: user.birthday },
  ];

  const incompleteFields = fields.reduce<string[]>((acc, field) => {
    if (field.name === UserFields.story) {
      if (isEmpty(field.value) || countWords(field.value) < 30) {
        acc.push(field.name);
      }
    } else if (isEmpty(field.value) || isNil(field.value)) {
      acc.push(field.name);
    }
    return acc;
  }, []);

  return incompleteFields;
};
